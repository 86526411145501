import { useSpring, animated } from '@react-spring/web';

import ic_loop from '../images/loop_a.png';

export const Fallback = () => {

  const anim = useSpring({
    from: { rotate: 0 },
    to: { rotate: 360 },
    loop : true
  })

  return (
    <div className='app view_center'>
      <animated.img
        style={{ transform: anim.rotate.to(value => `rotate(${value}deg)`) }}
        alt=''
        src={ic_loop}
      />
    </div>
  );

}
