import React, { Component, Suspense } from "react";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect,
} from "react-router-dom";

import { Fallback } from "./fallback/Default";

import "./App.css";

// Bootstrap CSS
import "bootstrap/dist/css/bootstrap.min.css";
// Bootstrap Bundle JS
import "bootstrap/dist/js/bootstrap.bundle.min";

const ExampleProduct = React.lazy(() => import("./page/ExampleProduct"));
const ExampleOrder = React.lazy(() => import("./page/ExampleOrder"));
const SessionPage = React.lazy(() => import("./page/SessionPage"));
const PushOrderPage = React.lazy(() => import("./page/PushOrderPage"));
const ApprovalPage = React.lazy(() => import("./page/ApprovalPage"))
const TestFlexsearch = React.lazy(() => import("./page/TestFlexsearch"));
const Login = React.lazy(() => import("./page/Login"));
const FormGrosir = React.lazy(() => import("./page/FormGrosir"));
const TempoCart = React.lazy(() => import("./page/TempCart"));
const Deeplink = React.lazy(() => import("./page/Deeplink"));

class App extends Component {
  constructor(props) {
    super(props);
    this.state = {
      message: [],
    };
  }

  render() {
    return (
      <div className="app">
        <Router>
          <Suspense fallback={<Fallback />}>
            <Switch>
              <Route exact path="/product">
                <ExampleProduct baseContext={this} />
              </Route>
              <Route exact path="/order">
                <ExampleOrder baseContext={this} />
              </Route>
              <Route exact path="/session">
                <SessionPage baseContext={this} />
              </Route>
              <Route exact path="/push-order">
                <PushOrderPage baseContext={this} />
              </Route>
              <Route exact path="/approval">
                <ApprovalPage baseContext={this} />
              </Route>
              <Route exact path="/test_flexsearch">
                <TestFlexsearch baseContext={this} />
              </Route>
              <Route exact path="/login">
                <Login baseContext={this} />
              </Route>
              <Route exact path="/form-grosir">
                <FormGrosir baseContext={this} />
              </Route>
              <Route exact path="/temp-cart">
                <TempoCart baseContext={this} />
              </Route>
              <Route exact path="/deeplink">
                <Deeplink baseContext={this} />
              </Route>
              <Route>
                <Redirect to="/login" />
              </Route>
            </Switch>
          </Suspense>
        </Router>
      </div>
    );
  }
}

export default App;
